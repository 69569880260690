.topbar {
  background: #45745e;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-inline-start: 40px;
  width: 100%;
  border-bottom: 1px solid #e8ecef;
  backdrop-filter: blur(4px);
  text-align: start;
  position: fixed;
  z-index: 3;
  -webkit-backdrop-filter: blur(4px);
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    padding-inline-start: unset;
    padding-top: 10px;
    padding-bottom: 52px;
    align-items: unset;
  }
}

.headerTopBar {
  font-family: 'Rubik';
  font-size: 27.0814px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  margin-right: 30px;

  @media (max-width: 640px) {
    width: 95px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-right: 0px;
    margin-inline-start: 15px;

    // font-size: 14px;
    // display: flex;
    // align-items: center;
    // position: absolute;
    // width: 93px;
    // height: 20px;
    // margin-inline-start: 44px;
    // margin-top: -25px;
  }
}

.topbar .iconTopBar {
  margin-inline-end: 28px;

  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }
}

.topbar .iconTopBarRtl {
  margin-inline-start: 28px;
  margin-inline-end: 0px;

  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }
}

.topbar .closeTopBar {
  position: absolute;
  left: 10vw;
}

.topbar .closeTopBarRtl {
  left: -20vw;
}

.topBar {
  display: inline !important;

  @media (max-width: 640px) {
    display: none !important;
  }
}

.topBarMedia {
  display: none !important;

  @media (max-width: 640px) {
    display: inline !important;
  }
}

.plansPricingBody {
  position: relative;
  top: 123px;
  height: 95vh;
  background-image: url('../../images/explanation/pricing-background.png');
}
.plansPricingBodyRtl {
  direction: rtl;
}

.plansPricingBody .content {
  padding-top: 40px;
  padding-inline-start: 230px;

  @media (max-width: 640px) {
    padding-inline-start: 42px;
  }
}

.plansPricingBody .bundles {
  margin-top: 54px;
  padding: 20px 53px;
  border: 1px solid #c5c7d1;
  border-radius: 13.5869px;
  width: 939px;
  width: 1080px;
  height: 450px;
  display: flex;
  margin-inline-start: 230px;

  @media (max-width: 640px) {
    flex-direction: column;
    margin-inline-start: 29px;
    height: 1740px;
    padding: 38px 41px;
    border: 1px solid #c5c7d1;
    width: 225px;
    gap: 20px;
  }
}

.plansPricingBody .bundles .card {
  border: 1px solid #c5c7d1;
  border-radius: 6px;
  width: 460px;
  height: 437px;
  padding: 20px;
  text-align: center;

  @media (max-width: 640px) {
    width: 254px;
    height: 477px;
    margin-inline-start: -11px;
  }
}

.bundles .card .title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #33354e;
  margin-bottom: 10px;
}

.bundles .card .sum {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #33354e;
  margin-bottom: 4px;
}

.bundles .card .minutes {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #33354e;
  margin-bottom: 8px;
}

.bundles .card .totalCost {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 15px;
  color: #33354e;
}

.bundles .card .priceper {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-bottom: 20px;
}

.bundles .card .auto {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  margin-top: 20px;
}

.bundles .card .btn {
  background: #33354e;
  border-radius: 6px;
  height: 38px;
  width: 170px;
  padding: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  position: relative;
  bottom: -67px;
}

.cardPro {
  background: linear-gradient(180deg, #ffffff 0%, #175253 0.01%, rgba(0, 0, 0, 0) 100%) !important;
  background-color: #8cc6ab !important;
}

.cardPro .img {
  position: absolute;
}

.header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #33354e;

  @media (max-width: 640px) {
    width: 313px;
    height: 120px;
    top: 120px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    display: flex;
    align-items: center;
  }
}

.subheader {
  margin-top: 20px;
  width: 509px;
  left: 230px;
  top: 521px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;

  color: #666666;

  @media (max-width: 640px) {
    width: 335px;
    height: 46px;
    top: 140px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.card {
  width: 412.1px;
  height: 224.18px;
  left: 266px;
  top: 12335.11px;
  background: #ffffff;
  border: 1.09894px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 11.874px;
  margin: 1%;

  @media only screen and(max-width:1245px) and(min-width:640px) {
    width: 300px;
    height: 215px;
    background: #33354e;
    border-radius: 6px;
    margin-right: 21px;
    margin-bottom: 80px;
  }

  @media (max-width: 640px) {
    width: 260px;
    height: 380px;
    margin-right: unset;
  }
}

.topbarRtl {
  direction: rtl !important;
}

// .langMenu {
//     width: 125px;
//     background: #33354e;
//     fill-opacity: 0.1;
//     border-radius: 8px;
//     padding-top: 3px;
//     padding-bottom: 3px;
//     position: absolute;
//     z-index: 230000;
//     top: 15px;
//     right: 170px;

// }
.langMenu {
  width: 125px;
  background: #33354e;
  fill-opacity: 0.1;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  z-index: 230000;
  top: 15px;
  right: 170px;

  @media (max-width: 640px) {
    display: none;
  }
}

.langMenuIcon {
  margin-inline-start: 30px;
}

.langMenuTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.topbarSide {
  position: relative;
}

.closeIcon {
  margin-inline-end: 5vw;
  cursor: pointer;

  @media (max-width: 640px) {
    margin-inline-end: 28px;
    margin-top: 35%;
    width: 18px;
  }
}

.closeIconRtl {
  margin-inline-end: 40px;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 18px;
    margin-inline-end: 28px;
    margin-top: 35%;
  }
}

.menuTopBar {
  width: 100vw;
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  top: 66px;
}

.menuTopBarPolicy {
  // top: 0px;
}

.menuTopBar .widgetIcon {
  width: 48px;
  height: 48px;
}

.menuSide {
  display: flex;
  position: relative;
  align-items: center;
}

.menuSideRtl {
  margin-inline-start: 5vw;
}

.topnav {
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Rubik';
  font-style: normal;
  line-height: 24px;
  padding-inline-start: 60px;
  display: flex;

  @media (max-width: 640px) {
    display: none;
  }
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  color: #a9b9ca;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-inline-end: 80px;
}

.topnav a:hover {
  border-bottom: 3px solid #33354e;
}

.topnav a.active {
  border-bottom: 3px solid #33354e;
  font-weight: 500;
  color: #33354e;
}

.borderFooter {
  border-top: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  text-align: -webkit-center;
}
.footer {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 75px;

  @media (max-width: 640px) {
    margin-top: -85px;
  }
}

.part {
  position: relative;
  width: 33%;

  @media (max-width: 640px) {
    width: 18%;
  }
}

.widgetIcon16 {
  width: 80px;

  @media (max-width: 640px) {
    width: 40px;
  }
}

.widgetIcon17 {
  display: none;

  @media (max-width: 640px) {
    position: fixed;
    height: 62px;
    width: 62px;
    left: 200px;
    bottom: 2%;
    z-index: 483647;
    cursor: pointer;
    border-radius: 60%;
  }
}

.topbarSideColmn1 {
  flex-direction: row;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    margin-inline-start: 20px;
    //margin-top: 50px;
  }
}

.topbarSideColmn2 {
  flex-direction: row;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    margin-inline-start: 173px;
    margin-top: -50px;
  }
}
.topbarSiderow1 {
  @media (max-width: 640px) {
    display: flex;
    flex-direction: row;
  }
}
.middlePart {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #33354e;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 14px;
  }
}
.middlePart .partCol {
  border-bottom: 1px solid #33354e;
  cursor: pointer;
  width: fit-content;
}

.middlePart .partColSpace {
  margin-inline-end: 20px;

  @media (max-width: 640px) {
    margin-inline-end: 0px;
  }
}

.middlePart .partRow {
  margin-top: 8px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contactIcon {
  margin-inline-end: 15px;
  cursor: pointer;
}

.sectionRtl {
  direction: rtl !important;
}

.rtlFirstBackground {
  transform: scaleX(-1);
}

.smallButtonRtl {
  right: 0px;
  left: 71.51%;
}

.dots {
  position: absolute;
  transform: scaleX(1);

  @media (max-width: 640px) {
    width: 100%;
    margin-inline-start: -13%;
  }
}

.dotsRtl {
  transform: scaleX(-1);

  @media (max-width: 640px) {
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    margin-inline-start: -13%;
  }
}

.animSection3 {
  transform: scaleX(1);
}

.animSection3Rtl {
  transform: scaleX(-1);
}

.swiper {
  width: 80%;
  height: fit-content;
}

.swiperSlide {
  width: 100%;
}

.eachSlide {
  display: flex;
  flex-direction: row;
}

.topbarRtl {
  direction: rtl !important;
}

.langMenu {
  width: 160px;
  background: #33354e;
  fill-opacity: 0.1;
  border-radius: 8px;
  margin: 1px 5vw;
  height: 38px;
  display: flex;
}

.langMenuRtl {
  margin: 1px 0vw;
}

.langMenuIcon {
  margin-inline-start: 30px;
}

.langMenuTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  justify-content: space-between;
  text-transform: capitalize;
  width: 100%;
  text-align: start;
}

.section6phone {
  margin-left: 111px;
  height: 613px;
  width: 473px;
  position: relative;
  top: 140px;

  @media (max-width: 640px) {
    margin: auto;
    width: 104%;
    height: 420px;
    top: 0px;
  }
}

.iphoneSection13 {
  display: block;

  @media (max-width: 640px) {
    display: none;
  }
}

.iphoneSection13Media {
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
}

.bottomArrow {
  position: relative;
  top: 10px;

  @media (max-width: 640px) {
    border-radius: 50%;
    cursor: pointer;
    inset: 23px auto 20px auto;
    width: 20px;
    height: 20px;
    border: 1px solid #a9b9ca;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

.menuTopBar {
  width: 100vw;
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  top: 66px;
}

.closeTopBarMenu {
  top: 0px;
}

.closeTopBarMenuSection {
  margin-top: 75px;
}

.openTopBarMenuSection {
  margin-top: 66px;
}

.landingPagebody {
  overflow-x: hidden;
}

.menuTopBar .widgetIcon {
  width: 48px;
  height: 48px;
}

.menuMobile .widgetIcon {
  width: 48px;
  height: 48px;

  @media (max-width: 640px) {
    margin-inline-start: 20px;
    margin-top: 8px;
    margin-bottom: 6px;
    width: 44px;
    height: 44px;
  }
}

.topnav {
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Rubik';
  font-style: normal;
  line-height: 24px;
  padding-inline-start: 60px;
  display: flex;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  color: #a9b9ca;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-inline-end: 80px;
}
.topnav a:hover {
  border-bottom: 3px solid #33354e;
}

.topnav a.active {
  border-bottom: 3px solid #33354e;
  font-weight: 500;
  color: #33354e;
}

.menuSide {
  display: flex;
  position: relative;
  align-items: center;
}

.menuSideRtl {
  margin-inline-start: 5vw;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}

.topbarSide {
  position: relative;
}

.row {
  display: flex;
}

.bottomArrowRtl {
  @media (max-width: 640px) {
    inset: 23px -15px 20px auto;
  }
}

.menuSideMobile {
  @media (max-width: 640px) {
    position: relative;
  }
}

.menuMobile {
  @media (max-width: 640px) {
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 84px;
    position: fixed;
    z-index: 3;
  }
}

.MenuIcon {
  @media (max-width: 640px) {
    margin-inline-end: 21px;
  }
}

.MenuIconRtl {
  @media (max-width: 640px) {
    transform: scaleX(-1);
  }
}

.closeMenuMobile {
  @media (max-width: 640px) {
    top: 0px !important;
  }
}

.section1Media {
  @media (max-width: 640px) {
    min-height: auto;
    background: #33354e;
    // top: -81px;
  }
}

.bottombar {
  background: #33354e;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 998;

  @media (max-width: 640px) {
    position: relative;
    z-index: 1;
    top: 3043px;
    // top: 3019px;
    /* margin-top: 2904px;*/
  }
}

.bottombar .subHeaderTopBar {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-inline-start: 20px;
  margin-inline-end: 18px;

  @media (max-width: 640px) {
    margin-inline-start: 63px;
    margin-top: 22px;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    margin-inline-start: -100px;
  }
}

.bottombar .button {
  width: 200px;
  background: #42c6a5;
  box-sizing: border-box;
  border: none;
  border-radius: 6px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5% 0;
  color: #ffffff;
  margin-inline-end: 20px;
  margin-inline-start: 20px;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 200px;
    position: absolute;
    height: 48px;
    margin-inline-start: 22.93%;
    background: #ffffff;
    border: 1px solid #33354e;
    box-sizing: border-box;
    border-radius: 8px;
    margin-inline-end: unset;
    margin-inline-start: unset;
    color: #33354e;
    font-size: 16px;
    line-height: 24px;
  }
}

.bottombarTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;

  @media (max-width: 640px) {
    width: 331px;
    height: auto;
    font-weight: 500;
    font-size: 16px;
    margin-top: 28px;
    text-align: start;
  }
}

.bottombar .flex {
  justify-content: space-between;
  display: flex;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bottomArrowRtl {
  @media (max-width: 640px) {
    inset: 23px -15px 20px auto;
  }
}
.borderSction16 {
  border-top: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  text-align: -webkit-center;
}

.section16 {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 75px;

  @media (max-width: 640px) {
    padding-bottom: 19px;
    flex-direction: column;
  }
}
.partimages {
  position: relative;
  width: 33%;

  @media (max-width: 640px) {
    width: 65%;
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
  }
}
