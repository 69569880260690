.topbar {
  background: #45745e;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-inline-start: 40px;
  width: 100%;
  border-bottom: 1px solid #e8ecef;
  backdrop-filter: blur(4px);
  text-align: start;
  position: fixed;
  z-index: 3;
  -webkit-backdrop-filter: blur(4px);
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 640px) {
    padding-inline-start: unset;
    padding-top: 10px;
    padding-bottom: 52px;
    align-items: unset;
    width: 100%;
  }
}
.topbarRtl {
  direction: rtl !important;
  @media (max-width: 640px) {
    width: 100%;
  }
}

.headerTopBar {
  font-family: 'Rubik';
  font-size: 27.0814px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  margin-right: 30px;
  @media (max-width: 640px) {
    width: 120px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-right: 0px;
    margin-inline-start: 15px;
  }
}

.topbar .iconTopBar {
  margin-inline-end: 28px;
  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }
}

.topbar .iconTopBarRtl {
  margin-inline-start: 28px;
  margin-inline-end: 0px;
  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }
}

.topbar .closeTopBar {
  position: absolute;
  left: 10vw;
}

.topbar .closeTopBarRtl {
  left: -20vw;
}

.bottombar .subHeaderTopBar {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-inline-start: 20px;
  margin-inline-end: 18px;
  @media (max-width: 640px) {
    top: 14px;
    margin-bottom: 6px;
    margin-top: 8px;
    width: 107px;
    height: 24px;
    bottom: 54px;
    font-weight: 400;
    font-size: 16px;
  }
}

.bottombar .button {
  width: 200px;
  background: #42c6a5;
  box-sizing: border-box;
  border: none;
  border-radius: 6px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5% 0;
  color: #ffffff;
  margin-inline-end: 20px;
  margin-inline-start: 20px;
  @media (max-width: 640px) {
    width: 200px;
    position: absolute;
    height: 48px;
    left: 22.93%;
    background: #ffffff;
    border: 1px solid #33354e;
    box-sizing: border-box;
    border-radius: 8px;
    margin-inline-end: unset;
    margin-inline-start: unset;
    color: #33354e;
  }
}

.topBar2 {
  display: inline !important;
  @media (max-width: 640px) {
    display: none !important;
  }
}

.topBarMedia {
  display: none !important;
  @media (max-width: 640px) {
    display: inline !important;
  }
}

.bottombar {
  background: #33354e;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 70000;
  @media (max-width: 640px) {
    width: 100%;
  }
}

.flexSection12 {
  text-align: start;
  padding-top: 200px;
  margin-inline-start: 163px;

  @media (max-width: 640px) {
    width: 100% !important;
    margin-left: 6% !important;
    padding-top: 0px !important;
    height: 70px;
    margin-inline-start: 48px !important;
  }
}

.flexSection2 {
  width: 80%;
  margin: 11%;
  @media (max-width: 640px) {
    width: 100% !important;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
  }
}

.smallButton {
  cursor: pointer;
  height: 48px;
  width: 280px;
  color: #ffffff;
  border: none;
  left: 0px;
  right: 71.51%;
  top: 240px;
  bottom: 95.59%;
  background: #42c6a5;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;

  @media (max-width: 640px) {
    right: 40.8%;
    bottom: 96.17%;
    border-radius: 6px;
    width: 200px;
    margin-bottom: 50px;
    top: 70%;
    font-size: 14px;
    line-height: 20px;
    height: 38px;
  }
}

.section15 {
  background-color: #ffffff;
  text-align: -webkit-center;
  text-align: center;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
    position: relative;
    height: unset;
  }
}

.section2 {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffffff;
  @media (max-width: 640px) {
  }
}

.section4Media {
  @media (max-width: 640px) {
    margin-top: 90px;
  }
}

.section7Media {
  @media (max-width: 640px) {
    margin: auto !important;
  }
}
.overflow {
  @media (max-width: 640px) {
    overflow-x: hidden;
  }
}

.section15Media {
  @media (max-width: 640px) {
    margin-top: 50px !important;
  }
}

.subSection {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 155px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    top: unset;
  }
}

.header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  width: 100%;
  top: 200px;
  z-index: 100;
  display: flex;
  align-items: center;
  place-content: center;
  @media (max-width: 640px) {
    width: 100%;
    top: 280px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    display: flex;
    left: 5%;
    right: 5%;
  }
}
.width50 {
  width: 50%;
  @media (max-width: 640px) {
    width: unset;
  }
}
.subheader {
  width: 100%;
  height: 100px;
  top: 297px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: start;

  @media (max-width: 640px) {
    width: 335px;
    height: 46px;
    top: 140px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.subheader2 {
  width: 100%;
  height: 72px;
  top: 153px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: start;

  @media (max-width: 640px) {
    width: 334px;
    height: 48px;
    top: 153px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.headerDark {
  width: 100%;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: center;
  @media (max-width: 640px) {
    width: 84%;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 8%;
  }
}
.headerDark3 {
  width: 100%;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: center;
  @media (max-width: 640px) {
    width: 80%;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }
}
.headerDark15 {
  width: 100%;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #33354e;
  align-items: center;
  place-content: center;
  margin-top: 5%;
  @media (max-width: 640px) {
    text-align: center !important;
    width: 100% !important;
    font-size: 30px;
    line-height: 38px;
  }
}

.headerDarkRtl {
  // width: 714px;
  width: 100%;
  height: 114px;
  // left: 1029px;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: center;
  direction: rtl;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 32px;
    line-height: 38px;
  }
}

.headerDarkRtl15 {
  width: 100%;
  /* height: 114px; */
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  place-content: center;
  direction: rtl;
  margin-top: 5%;
  @media (max-width: 640px) {
    text-align: center !important;
    width: 100% !important;
    font-size: 30px;
    line-height: 38px;
  }
}

.subheaderDark {
  // width: 594px;
  width: 100%;
  //width: 714px;
  top: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #33354e;
  text-align: start;

  @media (max-width: 640px) {
    width: 80% !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 7%;
  }
}

.subheaderDarkRtl {
  width: 100%;
  top: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #33354e;
  text-align: start;
  @media (max-width: 640px) {
    width: 80% !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 7%;
  }
}

.flex {
  display: flex;
  justify-content: center;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.step {
  width: 369px;
  height: 95px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c4c4c;
  @media (max-width: 640px) {
    width: 80%;
    height: unset;
  }
}

.headerStep {
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  @media (max-width: 640px) {
    font-size: 16px;
  }
}

.fully {
  width: 99px;
  height: 35px;
  left: 492px;
  top: 4618px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #42c6a5;
  justify-content: center;
  margin-top: -26px;
}

.fullyheader {
  width: 99px;
  left: 492px;
  top: 4618px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  color: #33354e;
  justify-content: center;
}

.containerfully {
  width: 259px;
  height: 200px;
  left: 412px;
  top: 4512px;
  background: #ffffff;
  border: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: -webkit-center;
  @media (max-width: 640px) {
    width: 158px;
    height: 158px;
  }
}

.imgFully {
  position: relative;
  width: 50%;
}

.gridFully {
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  grid-template-rows: 222px;
  grid-auto-flow: row;
  margin: auto;
  place-content: center;
  margin-bottom: 35px;

  @media only screen and(max-width:1245px) and(min-width:640px) {
    grid-template-columns: 150px 150px 150px 150px 150px 150px 150px;
  }

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 167px 167px;
    grid-template-rows: 167px 167px 167px 190px;
    grid-auto-flow: row;
    margin: auto;
    place-content: center;
    margin-bottom: 30px;
  }
}

.cons {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  grid-template-rows: 90px 90px 90px 90px;
  grid-auto-flow: row;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 640px) {
    grid-template-columns: 170px 170px;
    grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px;
  }
}

.voteContainer {
  width: 40px;
  height: 40px;
  left: 267px;
  top: 5788px;
  box-shadow: -3px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.grid {
  display: flex;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  grid-auto-flow: row;
}

.gridRtl {
  display: flex;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  grid-auto-flow: row;
  margin-left: 9px;
}

.closeMenuMobile {
  @media (max-width: 640px) {
    top: 0px !important;
  }
}

.section1 {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  display: inline;
  @media (max-width: 640px) {
    width: unset;
    display: none;
  }
}

.section1Media {
  @media (max-width: 640px) {
    min-height: auto;
    background: #33354e;
    top: 60px;
  }
}

.homeScreenMobile {
  display: none;

  @media (max-width: 640px) {
    display: inline;
    width: 100%;
    text-align: center;
    justify-content: center;
    background: #33354e;
  }
}
.menuSideMobile {
  @media (max-width: 640px) {
    position: relative;
    margin-inline-end: 15px;
  }
}

.flex1 {
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.flex1Rtl {
  display: flex;
  flex-direction: column-reverse;
}

.section7men {
  align-self: center;
  @media (max-width: 640px) {
    width: 603.83px;
    height: 403.21px;
    width: 301.83px;
    height: 317.21px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.section7menRtl {
  transform: scaleX(-1);
  align-self: center;

  @media (max-width: 640px) {
    width: 603.83px;
    height: 403.21px;
    width: 301.83px;
    height: 317.21px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.integrated {
  width: 185.2px;
  height: 157.78px;
  left: 1169.11px;
  top: 8547.62px;
  background: #ffffff;
  border-radius: 5.55283px;
  box-shadow: 2px 2px 2px 2px #e5e5e5;
}

.integratedgrid {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: 173px 173px 173px 173px;
  grid-auto-flow: row;
  margin-right: 150px;
}

.integratedgrid1 {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 173px;
  grid-auto-flow: row;
  margin-right: 9px;
}

.wooLogo {
  width: 277.8px;
  position: relative;
  top: unset;
  left: -85px;
}

.minimal {
  position: absolute;
  left: 145px;
  @media (max-width: 640px) {
    display: none;
  }
}

.section14img {
  position: relative;
  @media (max-width: 640px) {
    margin-top: 13%;
    margin-left: 7%;
    margin-right: 7%;
  }
}

.section14aimg {
  position: relative;
  @media (max-width: 640px) {
    margin-left: 17%;
    margin-top: unset !important;
    margin-bottom: unset;
    margin-right: unset;
    left: 45%;
  }
}

.section15Text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section2img {
  position: relative;
  @media (max-width: 640px) {
    width: 70%;
    margin-top: 15%;
  }
}

.section4img {
  position: relative;
  top: 18%;
  @media (max-width: 640px) {
    width: 88%;
    margin-top: 15%;
  }
}

.section15img {
  position: relative;
  @media (max-width: 640px) {
    width: 313.24px;
    height: 384.08px;
    margin-inline-end: 22px;
    bottom: 900px;
    display: none;
  }
}

.section15imgMobile {
  display: none;
  @media (max-width: 640px) {
    display: initial;
    position: relative;
  }
}

.section2menMedia {
  display: none;
  @media (max-width: 640px) {
    display: inline;
    position: relative;
  }
}

.section2imgRtl {
  left: 0;
  transform: scaleX(-1);
  @media (max-width: 640px) {
    left: 0;
    display: inline;
    width: 70%;
    margin-top: 15%;
  }
}

.section4imgRtl {
  position: relative;
  top: 18%;
  transform: scaleX(-1);
  @media (max-width: 640px) {
    width: 88%;
    margin-top: 15%;
  }
}

.section14imgRtl {
  left: 0;
  transform: scaleX(-1);
  @media (max-width: 640px) {
    left: 0;
    display: inline;
  }
}

.section14aimgRtl {
  left: 0;
  transform: scaleX(-1);
  @media (max-width: 640px) {
    left: 0;
    display: inline;
  }
}

.talkChat {
  position: absolute;
  left: 47px;
  top: 1385px;
  @media (max-width: 640px) {
    position: absolute;
    right: 160px;
    top: 219px;
  }
}

.talkChatRtl {
  position: absolute;
  right: 47px;
  top: 1385px;
  @media (max-width: 640px) {
    position: absolute;
    right: 160px;
    top: 219px;
  }
}

.leftArrowCurved {
  position: relative;
  top: 165px;
  left: 138px;
}

.numbersSentes {
  padding: 23px;
  font-size: 32px;
  width: 33.3%;
  @media (max-width: 640px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center !important;
  }
}

.gridCard {
  display: grid;
  grid-template-columns: 100px 235px;
  grid-template-rows: 88px;
  margin-top: 20px;
  @media (max-width: 640px) {
    grid-template-columns: 78px 212px;
  }
}

.imgCard {
  justify-self: center;
  width: 60%;
  height: 60%;
}

.card {
  width: 412.1px;
  height: 224.18px;
  left: 266px;
  top: 12335.11px;
  background: #ffffff;
  border: 1.09894px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 11.874px;
  margin: 1%;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    width: 300px;
    height: 215px;
    background: #33354e;
    border-radius: 6px;
    margin-right: 21px;
    margin-bottom: 80px;
  }
  @media (max-width: 640px) {
    width: 260px;
    height: 380px;
    margin-right: unset;
  }
}

.flexCard {
  place-content: center;
  display: flex;
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
}

.nameCard {
  height: 32px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.positionCard {
  width: 212px;
  height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.feedback {
  width: 345px;
  height: 96px;
  left: 301px;
  top: 12432px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c2f62;
  margin-top: -13px;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    width: 280px;
  }
  @media (max-width: 640px) {
    width: 70%;
    font-size: 14px;
  }
}

.section4Container {
  position: relative;
  top: 25%;
}

.section8 {
  position: relative;
  top: 13%;
  display: flex;
  flex-direction: row;
  background: #ebf3ef;
  place-content: center;
  height: 697.15px;
}

.borderSection11 {
  border: 1px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 20px;
  height: 700px;
  border-radius: 11px;
}

.slide-container {
  position: relative;
  top: 90px;
}

.section12Circle {
  position: absolute;
  left: 30%;
  margin-top: -249px;
  @media (max-width: 640px) {
    display: none;
  }
}

.section12CircleRtl {
  position: absolute;
  right: 30%;
  margin-top: -249px;
  @media (max-width: 640px) {
    display: none;
  }
}

.line {
  position: relative;
  height: 100%;
  width: 100%;
  left: 228px;
  transform: rotate(-8.32deg);
}

.lineRtl {
  position: relative;
  height: 100%;
  width: 100%;
  right: 228px;
  transform: rotate(-8.32deg);
}

.ellipse {
  margin-top: -180px;
  margin-right: 640px;
}

.borderSction14 {
  border: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: -webkit-center;
}

.section7Circle {
  position: absolute;
  margin-top: -270px;
  @media (max-width: 640px) {
    display: none;
  }
}

.section7CircleRtl {
  position: absolute;
  left: 435px;
  margin-top: -270px;
  transform: scaleX(-1);
  @media (max-width: 640px) {
    display: none;
  }
}

.CloseCircle {
  display: grid;
  grid-template-columns: 182px 182px;
  grid-template-rows: 50px 50px;
  margin-top: 25px;
  margin-bottom: 30px;
  @media (max-width: 640px) {
    display: block;
  }
}

.backSection8 {
  position: absolute;
  margin-top: 100px;
  display: inline;
  @media (max-width: 640px) {
    display: none;
  }
}

.backSection8Media {
  position: absolute;
  margin-top: 100px;
  display: none;
  @media (max-width: 640px) {
    display: inline;
    margin-top: 152px;
  }
}

.section8Elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 640px) {
    margin-top: 45px;
  }
}

.numbersSentesMedia {
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
}

.numbersSection8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 640px) {
    display: none;
  }
}

.numbers {
  @media (max-width: 640px) {
    display: none;
  }
}

.AiOutlineArrowDown {
  border-radius: 50%;
  z-index: 70000;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  inset: auto 37px 107px auto;
  position: fixed;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #a9b9ca;
  box-sizing: border-box;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px #888888;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    display: none;
  }
  @media (max-width: 640px) {
    display: none;
  }
}

.bottombarTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  @media (max-width: 640px) {
    width: 331px;
    height: auto;
    font-weight: 500;
    font-size: 16px;
    margin-top: 28px;
    text-align: start;
    margin-inline-start: 17px;
  }
}

.sectionRtl {
  direction: rtl !important;
}

.rtlFirstBackground {
  transform: scaleX(-1);
}

.smallButtonRtl {
  right: 0px;
  left: 71.51%;
}

.smallButtonM1 {
  @media (max-width: 640px) {
    top: 160px;
    z-index: 100;
  }
}

.dots {
  position: absolute;
  transform: scaleX(1);
  @media (max-width: 640px) {
    height: 50%;
    width: 100%;
  }
}

.dotsRtl {
  transform: scaleX(-1);
  @media (max-width: 640px) {
    position: absolute;
    height: 50%;
    width: 100%;
  }
}

.animSection3 {
  transform: scaleX(1);
}

.animSection3Rtl {
  transform: scaleX(-1);
}

.flexSection3 {
  padding: 181px 342px;
  @media (max-width: 640px) {
    text-align: -webkit-center;
    padding: 98px 22px;
  }
}

.section3Img {
  position: absolute;
  left: 45%;
  margin-top: -3%;
  @media (max-width: 640px) {
    left: 30%;
  }
}

.container {
  width: 100%;
  height: 100vh;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 80%;
  height: fit-content;
}

.swiperSlide {
  width: 100%;
}

.eachSlide {
  display: flex;
  flex-direction: row;
}

.langMenu {
  width: 125px;
  background: #33354e;
  fill-opacity: 0.1;
  border-radius: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  z-index: 230000;
  top: 15px;
  right: 170px;
}

.langMenuIcon {
  margin-inline-start: 30px;
}

.langMenuTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.section6phone {
  margin-left: 111px;
  height: 613px;
  width: 473px;
  position: relative;
  top: 55px;
  @media (max-width: 640px) {
    margin: auto;
    width: 340px;
    height: 500px;
  }
}

.iphoneSection13 {
  display: block;
  @media (max-width: 640px) {
    display: none;
  }
}

.iphoneSection13Media {
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
}

.bottomArrow {
  position: relative;
  top: 10px;
  @media (max-width: 640px) {
    border-radius: 50%;
    cursor: pointer;
    inset: auto 30px -11px auto;
    width: 39px;
    height: 36px;
    border: 1px solid #a9b9ca;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

.row {
  display: flex;
}

.topbarSide {
  position: relative;
  @media (max-width: 640px) {
    margin-inline-start: -9px;
  }
}

.closeIcon {
  margin-inline-end: 5vw;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 18px;
    margin-inline-end: 28px;
    margin-top: 35%;
  }
}

.closeIconRtl {
  margin-inline-end: 40px;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 18px;
    margin-inline-end: 28px;
    margin-top: 35%;
  }
}

.menuTopBar {
  width: 100vw;
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  top: 66px;
}

.menuTopBar .widgetIcon {
  width: 48px;
  height: 48px;
}

.menuSide {
  display: flex;
  position: relative;
  align-items: center;
}

.menuSideRtl {
  margin-inline-start: 5vw;
}

.topnav {
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Rubik';
  font-style: normal;
  line-height: 24px;
  padding-inline-start: 60px;
  display: flex;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  color: #a9b9ca;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-inline-end: 80px;
}

.topnav a:hover {
  border-bottom: 3px solid #33354e;
}

.topnav a.active {
  border-bottom: 3px solid #33354e;
  font-weight: 500;
  color: #33354e;
}

.menuMobile .widgetIcon {
  width: 48px;
  height: 48px;

  @media (max-width: 640px) {
    margin-inline-start: 20px;
    margin-top: 8px;
    margin-bottom: 6px;
    width: 44px;
    height: 44px;
  }
}

.menuMobile {
  @media (max-width: 640px) {
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 84px;
    position: fixed;
    z-index: 3;
  }
}
.RowSection5 {
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    display: unset;
  }
}
.section5 {
  text-align: start;
  background: #f6f5fb;
  display: flex;
  @media (max-width: 640px) {
    height: unset;
  }
}

.headerTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #33354e;
  @media (max-width: 640px) {
    font-size: 32px;
    line-height: 38px;
    margin-right: 7%;
    margin-left: 7%;
    margin-top: 15%;
  }
}

.subHeaderTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #33354e;
  width: 70%;
  @media only screen and(max-width:1024px) and(min-width:640px) {
    width: 65%;
  }
  @media (max-width: 640px) {
    width: 60% !important;
    font-size: 18px;
    margin-top: 7%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: unset;
  }
}

.subHeaderTitleWide {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #33354e;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 50%;
  @media (max-width: 640px) {
    width: 60% !important;
    font-size: 18px;
    margin-top: 7%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: unset;
  }
}

.subHeaderTitleWide2 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #33354e;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 40vw;
  @media (max-width: 640px) {
    width: 73vw !important;
  }
}

.bold {
  font-weight: 500;
}

.section6 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    height: unset;
    width: 100%;
  }
}

.flexSection6 {
  width: 18%;
}

.columnSection6 {
  margin-top: 6%;
  display: flex;
  flex-direction: column;
}
.imgSection {
  width: 900px;
  border-radius: 2%;
  position: relative;
  top: 6%;
  @media (max-width: 640px) {
    width: 480px;
    height: 220px;
    margin-bottom: 12%;
    margin-top: 12%;
    margin-left: 3%;
  }
}
.imgSection2 {
  width: 900px;
  height: 460px;
  border-radius: 2%;
  position: relative;
  top: 20%;
  @media (max-width: 640px) {
    width: 480px;
    height: 220px;
    position: relative;
    top: 10%;
  }
}

.imgSectionRtl {
  width: 900px;
  border-radius: 2%;
  position: relative;
  top: 6%;
  @media (max-width: 640px) {
    width: 480px;
    height: 220px;
    margin-bottom: 12%;
    margin-top: 12%;
    margin-left: 3%;
  }
}
.imgSectionRtl2 {
  width: 900px;
  height: 460px;
  border-radius: 2%;
  position: relative;
  top: 20%;
  @media (max-width: 640px) {
    width: 480px;
    height: 220px;
    position: relative;
    top: 10%;
  }
}

.signupUrlSmall {
  height: 43px;
  width: 150px;
  right: 0%;
  font-family: Rubik;
  font-size: 16px;
  color: #33354e;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  position: relative;
  border-radius: 8px;
  background: rgba(66, 198, 165, 1);
  border-color: white;
  cursor: pointer;
}

.signupSmall {
  height: 65px;
  width: 280px;
  border-radius: nullpx;
  font-family: Rubik;
  font-weight: 500;
  font-size: 20px;
  color: #33354e;
  border-radius: 8px;
  background: #42c6a5;
  border: none;
  cursor: pointer;
  margin-bottom: 5%;
  margin-top: 5%;
}

.signupUrl {
  height: 65px;
  width: 280px;
  top: 16.4375px;
  border-radius: nullpx;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  position: relative;
  right: 50%;
  left: 50%;
  border-radius: 8px;
  background: rgba(66, 198, 165, 1);
  border-color: white;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'><path%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23444444'%2F><%2Fsvg>") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23444444'%2F%3E%3C%2Fsvg%3E") !important;
}

.flexSection15 {
  background: #f0fffb;
  border-radius: 18px 18px 0px 0px;
  margin-top: 5%;
  border-bottom: 1px solid #c5c7d1;
  width: 80%;
  @media (max-width: 640px) {
    align-self: center;
    margin-inline-start: unset !important;
    margin-inline-end: unset !important;
    margin-top: unset;
    padding: unset;
    width: 90%;
    margin-bottom: 5%;
  }
}

.borderSction16 {
  box-sizing: border-box;
  text-align: -webkit-center;
}

.section16 {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 75px;
  @media (max-width: 640px) {
    padding-top: 93px;
    margin-top: -101px;
    height: 39px;
  }
}

.part {
  position: relative;
  width: 33%;
  @media (max-width: 640px) {
    width: 18%;
    margin-top: -34px;
  }
}

.widgetIcon16 {
  width: 80px;
  @media (max-width: 640px) {
    width: 40px;
  }
}

.middlePart {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #33354e;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 14px;
    margin-top: -70px;
  }
}

.middlePart .partCol {
  border-bottom: 1px solid #33354e;
  cursor: pointer;
  width: fit-content;
}

.middlePart .partColSpace {
  margin-inline-end: 20px;

  @media (max-width: 640px) {
    margin-inline-end: 0px;
  }
}

.middlePart .partRow {
  margin-top: 8px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contactIcon {
  margin-inline-end: 15px;
  cursor: pointer;
}

.flexSection15 .signupSmall {
  @media (max-width: 640px) {
    top: 28px;
    height: 45px;
    width: 200px;
    position: unset;
    margin: 10%;
  }
}
.flexEndMobile {
  @media (max-width: 640px) {
    text-align-last: end;
  }
}

.flexSection15Img {
  display: none;
  @media (max-width: 640px) {
    height: 190px;
    display: inline;
  }
}

.section15green {
  display: none;
  @media (max-width: 640px) {
    margin-inline-start: 10px !important;
    width: 330px;
    height: 339px;
    background: #34efbd;
    margin-top: -83vh;
    position: relative;
    display: initial;
  }
}

.section4 {
  background-color: #ffffff;
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  z-index: 70001;

  @media (max-width: 640px) {
    display: none;
  }
}

.popupBody {
  background: #ffffff;
  margin-top: 15%;
  margin-bottom: 10%;
  margin-inline-start: 30%;
  padding-top: 32px;
  padding-bottom: 92px;
  padding-inline-start: 70px;
  padding-inline-end: 34px;
  border-radius: 27.0233px;
  width: 704.32px;
  height: 310px;
}

.popupFlex {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.popupSide {
  position: relative;
}

.popupClose {
  cursor: pointer;
  text-align: end;
}

.popupImg {
  display: flex;
}

.popupTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #33354e;
  width: 312px;
  padding-bottom: 40px;
}

.popupSubtitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #33354e;
  width: 319px;
  padding-bottom: 40px;
}

.popupBtn {
  width: auto;
  height: 45px;
  background: #42c6a5;
  border: none;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  padding: 2% 3%;
  cursor: pointer;
}
