* {
  margin: 0;
  padding: 0;
}

body {
  background: #eee;
  position: relative;
  width: 100%;
  height: 100vh;
  /* font-family: 'Martel', serif;
    overflow: hidden; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 75%;
  height: 100%;
  /* transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(1) !important; */
  /* transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.8) !important; */
}

@media (max-width: 768px) {
  .swiper-slide {
    width: 250px;
    height: auto;
    margin-bottom: 7%;
  }
}

.swiper-slide img {
  display: block;
  width: 83%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.title_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 3vw 0 2vw 0;
}

@media (max-width: 768px) {
  .title_wrapper {
    padding-top: 13vw;
  }
}

.title_wrapper .reactLogo {
  width: 5vw;
  height: 5vw;
  margin-right: 1vw;
}

@media (max-width: 768px) {
  .title_wrapper .reactLogo {
    width: 25vw;
    height: 25vw;
  }
}

.title_wrapper .reactLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.title_wrapper .title_ {
  color: #fff;
  text-align: left;
  font-size: 2vw;
}

@media (max-width: 768px) {
  .title_wrapper .title_ {
    font-size: 7vw;
  }
}

.title_wrapper .title_ span {
  display: block;
}

.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  border-radius: 5px;
}
/* .swiper-wrapper{
    transition-duration: 0ms !important;
    transform: translate3d(-14160px, 0px, -213px) !important;
  } */
