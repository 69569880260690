.control {
  @media (max-width: 640px) {
    background: #45745e;
    border: 1px solid #c5c7d1;
    border-radius: 8px;
    height: 60px;
  }
}

.placeholder {
  @media (max-width: 640px) {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 20px 0px;
    padding-inline-start: 16px;
  }
}

.menu {
  @media (max-width: 640px) {
    background: #ffffff;
    border: 1px solid #c5c7d1;
    box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.24);
    border-radius: 4px;
    height: 232px;
    z-index: 4;
    position: fixed;
    width: 303px;
    margin-top: 6px;
    line-height: 48px;
    padding-inline-start: 15px;
    overflow-y: scroll;
    font-family: 'Rubik';
    font-style: normal;
    font-size: 14px;
  }
}
