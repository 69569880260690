.topbar {
  background: #45745e;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-inline-start: 40px;
  width: 100%;
  border-bottom: 1px solid #e8ecef;
  backdrop-filter: blur(4px);
  text-align: start;
  position: fixed;
  z-index: 3;
  -webkit-backdrop-filter: blur(4px);
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    padding-inline-start: unset;
    padding-top: 10px;
    padding-bottom: 52px;
    align-items: unset;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.headerTopBar {
  font-family: 'Rubik';
  font-size: 27.0814px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  margin-right: 30px;

  @media (max-width: 640px) {
    width: 96px;
    height: 20px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-right: 0px;
    margin-inline-start: 15px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}

.topbar .iconTopBar {
  margin-inline-end: 28px;

  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-inline-end: 18px;
    width: 26px;
  }
}

.topbar .iconTopBarRtl {
  margin-inline-start: 28px;
  margin-inline-end: 0px;

  @media (max-width: 640px) {
    width: 16px;
    height: auto;
    margin: 6px;
  }
}

.closeIcon {
  margin-inline-end: 5vw;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 18px;
    margin-inline-end: 28px;
    margin-top: 35%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 20px;
  }
}

.closeIconRtl {
  margin-inline-end: 40px;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 18px;
    margin-inline-end: 28px;
    margin-top: 35%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 20px;
  }
}

.topbar .closeTopBar {
  position: absolute;
  left: 10vw;
}

.topbar .closeTopBarRtl {
  left: -20vw;
}

.bottombar .subHeaderTopBar {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-inline-start: 20px;
  margin-inline-end: 18px;

  @media (max-width: 640px) {
    margin-inline-start: 63px;
    margin-top: 22px;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    margin-inline-start: -100px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.contectForm {
  position: absolute;
  background: #47495f;
  border-radius: 0px 0px 33px 33px;
  margin-inline-start: 48%;
  width: 32%;
  height: 83%;
  @media (max-width: 640px) {
    position: absolute;
    width: 335px;
    background: rgba(250, 250, 250, 0.03);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    border-radius: 10px;
    margin-inline-start: auto;
    left: 3%;
    right: 3%;
    top: 5px;
    height: 70%;
  }
}

.bottombar .button {
  width: 200px;
  background: #42c6a5;
  box-sizing: border-box;
  border: none;
  border-radius: 6px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5% 0;
  color: #ffffff;
  margin-inline-end: 20px;
  margin-inline-start: 20px;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 200px;
    position: absolute;
    height: 48px;
    margin-inline-start: 22.93%;
    background: #ffffff;
    border: 1px solid #33354e;
    box-sizing: border-box;
    border-radius: 8px;
    margin-inline-end: unset;
    margin-inline-start: unset;
    color: #33354e;
    font-size: 16px;
    line-height: 24px;
  }
}

.topBar {
  display: inline !important;

  @media (max-width: 640px) {
    display: none !important;
  }
}

.topBarMedia {
  display: none !important;

  @media (max-width: 640px) {
    display: inline !important;
  }
}

.bottombar {
  background: #33354e;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 998;

  @media (max-width: 640px) {
    position: unset;
    z-index: 1;
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.flexSection1 {
  width: 50%;
  text-align: start;

  @media (max-width: 640px) {
    width: 100% !important;
    margin-left: 6% !important;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 60% !important;
  }
}

.flexSection1MobileRtl {
  @media (max-width: 640px) {
    margin-right: -9% !important;
    direction: ltr;
  }
}

.flexSection1Rtl {
  margin-right: 2%;
  text-align: start;

  @media (max-width: 640px) {
    width: 100% !important;
    margin-right: 6%;
    margin-left: 0%;
    // text-align:center;
  }
}

.smallButton {
  cursor: pointer;
  height: 48px;
  width: 281px;
  color: #33354e;
  border: none;
  // left: 0px;
  left: 132px;
  right: 71.51%;
  top: 80%;
  bottom: 95.59%;
  background: #42c6a5;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  font-family: 'Rubik';
  @media (max-width: 640px) {
    right: 40.8%;
    bottom: 96.17%;
    border-radius: 6px;
    width: 200px;
    margin-bottom: 100px;
    top: 70%;
    font-size: 14px;
    line-height: 20px;
    height: 38px;
    border-radius: 6px;
    width: 200px;
    top: 36%;
    font-size: 14px;
    line-height: 20px;
    height: 38px;
    left: 5%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 14px;
    line-height: 20px;
    width: 200px;
    height: 38px;
    border-radius: 6px;
  }
}
.smallButton15 {
  cursor: pointer;
  color: #ffffff !important;
  border: none;
  background: #42c6a5;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  font-family: 'Rubik';
  height: 5.4vh;
  width: 50%;
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0 !important;
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 640px) {
    border-radius: 6px;
    width: 200px;
    font-size: 14px;
    line-height: 20px;
    position: absolute !important;
    top: 90%;
    left: 19%;
    right: 19%;
  }
}

.fieldFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3px;
  padding-inline-end: 11%;
  margin-inline-start: -4.5%;
  @media (max-width: 640px) {
    margin-inline-start: -68px;
    padding-inline-end: 17px;
  }
}

.fieldFlex1 {
  display: flex;
  flex-direction: row;
  margin-inline-start: unset !important;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 640px) {
    padding-inline-end: unset;
    margin-inline-start: unset;
    display: unset;
    justify-content: unset;
    text-align: -webkit-center;
  }
}
.fieldFlex2 {
  display: flex;
  flex-direction: row;
  margin-inline-start: unset !important;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 640px) {
    padding-inline-end: unset;
    margin-inline-start: unset;
    display: unset;
    justify-content: unset;
    text-align: -webkit-center;
    width: 309px;
    position: relative;
    left: 7px;
    right: 7px;
  }
}

.fieldFlexC {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  @media (max-width: 640px) {
    margin-left: unset;
    margin-right: unset;
    position: absolute;
  }
}

.section {
  width: 100vw;
  overflow-x: hidden;
  background-color: #ffffff;
  height: 100vh;
  text-align: -webkit-center;
  @media (max-width: 640px) {
    position: relative;
    top: auto;
    height: unset;
  }
}

.section4Media {
  @media (max-width: 640px) {
    margin-top: 90px;
  }
}

.section7Media {
  @media (max-width: 640px) {
    margin: auto !important;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 71%;
  }
}

.section2Media {
  @media (max-width: 640px) {
    margin-top: 70px !important;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 185px !important;
  }
}

.subSection {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 155px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    top: unset;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0;
  }
}

.header {
  // width: 714px;
  width: 96%;
  height: 164px;
  // left: 267px;
  top: 215px;
  // font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-align: start;

  @media (max-width: 640px) {
    width: 325px;
    height: auto;
    top: 120px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    display: flex;
    align-items: center;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 87%;
    font-size: 32.5px;
    line-height: 48px;
    top: 130px;
  }
}

.header15 {
  position: absolute;
  width: 276px;
  height: 37px;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: #ffffff;
  // margin-inline-start: 88px;
  // padding-top: 146px;
  margin-inline-start: 10%;
  padding-top: 19%;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 32px;
    line-height: 48px;
  }

  @media (max-width: 640px) {
    position: absolute;
    width: 180px !important;
    height: 19px;
    font-size: 32px !important;
    line-height: 31px !important;
    margin-inline-start: 28px;
    margin-top: -14px;
  }
}

.bigInputText {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  //height: 60px;
  //width: 489px;
  padding-top: 0.7%;
  margin-bottom: -28px;
  font-family: Rubik;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: start;
  //margin-inline-start: 91px;
  top: 283px;
  height: 6vh;
  width: 100%;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    border-radius: 5.1875px;
    height: 5.4vh;
  }

  @media (max-width: 640px) {
    width: 309px;
    height: 56px;
    margin-inline-start: 7px;
  }
}

.field {
  padding-top: 0.7%;
  //height: 55px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  margin-bottom: 2.5vh;
  font-family: Rubik;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: start;
  width: 100%;
  margin-top: 42px;
  margin-inline-start: 15px;
  height: 5.4vh;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 5.1vh;
    border-radius: 5.1875px;
  }

  @media (max-width: 640px) {
    align-items: center;
    padding-top: 0vh;
    font-size: 1vw;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 8px;
    height: 52px;
    margin-top: 45px;
  }
}

[class*='qc6sy'] {
  color: #ffffff !important;
}

::-moz-selection {
  background-color: Black;
  //color:Black;
}

.bigInputText1 {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  //height: 159px;
  //width: 488px;
  padding-top: 0.7%;
  margin-bottom: 30px;
  font-family: Rubik;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: start;
  position: relative;
  //margin-inline-start: 91px;
  top: -8px;
  // margin-inline-start: 10%;
  // width: 79%;
  height: 14vh;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    border-radius: 5.1875px;
    top: 0;
  }

  @media (max-width: 640px) {
    width: 309px;
    margin-inline-start: 7px;
    margin-top: 10px;
    height: 130px;
  }
}

.bigInputText2 {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 14px;
  font-family: Rubik;
  margin-top: 5%;
  height: 5.4vh;
  width: 49%;
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    border-radius: 5.1875px;
  }

  @media (max-width: 1800px) {
    padding-top: 0%;
  }

  @media (max-width: 640px) {
    width: 309px;
    height: 56px;
    margin-inline-start: 7px;
    padding-top: 0.7%;
  }
}

.fieldSelectType {
  width: 91px !important;
  margin-top: 315px !important;
  margin-inline-start: 89px !important;
}

.selectActivity {
  position: relative;
  z-index: 2000;
  height: auto;
  max-height: 180px;
  background-color: #ffffff;
  border: 1px solid #a9b9ca;
  box-sizing: border-box;
  box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.24);
  border-radius: 4px;
  font-size: 14px;
  line-height: 40px;
  padding: 20px 15px 0px 15px;
  overflow-y: scroll;
  text-align: start;
  margin-top: 10px;

  @media (max-width: 640px) {
    font-size: 12px;
    padding: 0px 15px 0px 15px;
  }
}

.selectActivity::-webkit-scrollbar {
  width: 8px;
}

.selectActivity::-webkit-scrollbar-thumb {
  background: #a9b9ca;
  border-radius: 4px;
}

.subheader15 {
  position: absolute;
  width: 100%;
  height: 100px;
  //margin-inline-start: 89px;
  padding-top: 271px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: start;
  width: 594px;
  margin-inline-start: 11%;
  margin-top: 0%;

  @media (max-width: 1800px) {
    margin-top: 3%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 640px) {
    position: absolute;
    width: 264px !important;
    height: 17px;
    font-size: 16px !important;
    line-height: 24px !important;
    line-height: 24px !important;
    margin-top: 87px;
    margin-inline-start: 28px;
    margin-top: 17%;
  }
}

.subheader {
  width: 100%;
  height: 100px;
  top: 210px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: start;
  width: 594px;

  @media (max-width: 640px) {
    width: 337px;
    height: auto;
    top: 140px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
    width: 400px;
    height: auto;
    top: 150px;
  }
}

.subheader2 {
  height: 72px;
  top: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-align: start;
  width: 615px;

  @media (max-width: 640px) {
    width: 334px;
    height: auto;
    // left: 20px;
    top: 153px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
    width: 400px;
    height: auto;
    top: 168px;
  }
}

.headerDark {
  font-family: 'Rubik';
  width: 100%;
  height: 114px;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: center;

  @media (max-width: 640px) {
    width: 92%;
    font-size: 32px;
    line-height: 38px;
    height: auto;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 32px;
    line-height: 48px;
    height: auto;
  }
}

.headerDarkRtl {
  font-family: 'Rubik';
  width: 100%;
  height: 114px;
  // left: 1029px;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: center;
  direction: rtl;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 32px;
    line-height: 38px;
    height: auto;
  }
}

.subheaderDark {
  // width: 594px;
  width: 100%;
  //width: 714px;
  height: 108px;
  top: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #33354e;
  text-align: start;
  font-family: 'Rubik';

  @media (max-width: 640px) {
    width: 80% !important;
    height: auto;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
    height: auto;
  }
}

.subheaderDark2 {
  font-family: 'Rubik';
  // width: 594px;
  width: 100%;
  //width: 714px;
  height: 108px;
  top: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #33354e;
  text-align: start;
  margin-top: 40px;

  @media (max-width: 640px) {
    top: 0px;
    width: 80% !important;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 17px;
    margin-bottom: 60px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 24px;
    line-height: 36px;
    color: #33354e;
  }
}

.flex {
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    display: unset;
  }

  // margin-bottom: 90px;
  // margin-top: 30px;
}

.step {
  width: 369px;
  height: 95px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c4c4c;
  font-family: 'Rubik';

  @media (max-width: 640px) {
    width: 90%;
    height: unset;
  }
}

.stepRtl {
  direction: rtl;
  width: 369px;
  height: 95px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4c4c4c;

  @media (max-width: 640px) {
    width: 90%;
    height: unset;
  }
}

.stepCard {
  margin: 0 2%;

  @media (max-width: 640px) {
    margin-top: 74px;
  }
}

.headerStep {
  // width: 248px;
  height: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: #33354e;
  margin-top: 8px;
  margin-inline-start: 10px;
  font-family: 'Rubik';

  @media (max-width: 640px) {
    // width: 50%;
    font-size: 16px;
    color: #33354e;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.headerStepinCard {
  height: 36px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: start;
  color: #33354e;
  margin-top: 8px;
  margin-inline-start: 10px;
  margin-bottom: 5px;

  @media (max-width: 640px) {
    // width: 50%;
    font-size: 16px;
  }
}

.fully {
  width: 99px;
  height: 35px;
  left: 492px;
  top: 4618px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #42c6a5;
  justify-content: center;
  margin-top: -26px;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: -10px;
    height: auto;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.fullyheader {
  width: auto;
  // height: 64px;
  left: 492px;
  top: 4618px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  color: #33354e;
  justify-content: center;

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
    height: auto;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.containerfully {
  width: 259px;
  height: 200px;
  left: 412px;
  top: 4512px;
  background: #ffffff;
  border: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: -webkit-center;

  @media (max-width: 640px) {
    width: 100%;
    height: 158px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 162px;
    height: 162px;
  }
}

.imgFully {
  position: relative;
  /* bottom: 11px; */
  width: 50%;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 47%;
  }
}

.gridFully {
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  grid-template-rows: 222px;
  grid-auto-flow: row;
  margin: auto;
  place-content: center;
  margin-bottom: 35px;

  @media only screen and(max-width:1300px) and(min-width:641px) {
    grid-template-columns: 180px 180px 180px 180px;
    grid-template-rows: 180px;
  }

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 167px 167px 167px 190px;
    grid-auto-flow: row;
    margin: auto;
    place-content: center;
    margin-bottom: 30px;
    gap: 0px 2%;
    padding: 0px 20px;
  }
}

.cons {
  // height: 60px;
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  grid-template-rows: 90px 90px 90px 90px;
  grid-auto-flow: row;
  margin: auto;
  // place-content: center;
  margin-bottom: 30px;
  margin-top: 30px;

  @media (max-width: 640px) {
    grid-template-columns: 170px 170px;
    grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px;
  }
}

.voteContainer {
  width: 40px;
  height: 40px;
  left: 267px;
  top: 5788px;
  // opacity: 0.3;
  box-shadow: -3px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.grid {
  // height: 60px;
  display: flex;
  //  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  grid-auto-flow: row;
  // margin: auto;
  // place-content: start;
  // margin-right: 9px;
  // margin-bottom: 30px;
}

.gridRtl {
  // height: 60px;
  display: flex;
  //  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  grid-auto-flow: row;
  // margin: auto;
  // place-content: start;
  margin-left: 9px;
  // margin-bottom: 30px;
}

.homeScreen {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  display: inline;

  @media (max-width: 640px) {
    display: none;
  }
}

.section1Media {
  @media (max-width: 640px) {
    min-height: auto;
    background: #33354e;
    top: 60px;
  }
}

.homeScreenMobile {
  display: none;

  @media (max-width: 640px) {
    display: inline;
    width: 100%;
    position: absolute;
    left: 0%;
    text-align: center;
    justify-content: center;
    margin-top: 15%;
    // margin-inline-start: -6%;
  }
}

.homeScreenMobile15 {
  display: none;

  @media (max-width: 640px) {
    display: inline;
    width: 100%;
    position: relative;
    left: 0%;
    text-align: center;
    justify-content: center;
    left: 0px;
    display: inline;
    margin-bottom: -4px;
  }
}

.flex1 {
  display: flex;
  flex-direction: row;

  @media (max-width: 640px) {
    display: unset;
  }
}

.section7men {
  // height: 445px;
  // width: 445px;
  align-self: center;

  @media (max-width: 640px) {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.section7menRtl {
  transform: scaleX(-1);
  align-self: center;

  @media (max-width: 640px) {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.integrated {
  width: 185.2px;
  height: 157.78px;
  left: 1169.11px;
  top: 8547.62px;
  background: #ffffff;
  border-radius: 5.55283px;
  box-shadow: 2px 2px 2px 2px #e5e5e5;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 144.01px;
    height: 122.69px;
  }
}

.integratedgrid {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: 173px 173px 173px 173px;
  grid-auto-flow: row;
  // place-content: start;
  margin-right: 150px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    grid-template-columns: 156px 156px 156px;
    grid-template-rows: 135px 135px 135px 135px;
    margin-right: 35.36px;
  }
}

.integratedgrid1 {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 173px;
  grid-auto-flow: row;
  // place-content: start;
  margin-right: 9px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    grid-template-columns: 156px 200px;
    grid-template-rows: 135px;
    place-content: end;
  }
}

.wooLogo {
  width: 277.8px;
  position: relative;
  top: unset;
  left: -85px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 216px;
    left: -19px;
  }
}

.minimal {
  position: absolute;
  left: 145px;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: -90px;
    left: -30px;
  }
}

.section2men {
  position: relative;
  left: 3px;

  // position: absolute;
  // left:215px;
  // transform: scaleX(-1);
  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 500px;
  }
}

.section2menMedia {
  display: none;

  @media (max-width: 640px) {
    display: inline;
    position: relative;
    width: 80%;
  }
}

.section2menRtl {
  position: absolute;
  right: 5%;
  transform: scaleX(-1);

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 500px;
  }
}

.talkChat {
  position: absolute;
  left: 47px;
  top: 1385px;

  @media (max-width: 640px) {
    position: absolute;
    right: 160px;
    top: 219px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 1030px;
    width: 280px;
  }
}

.talkChatRtl {
  position: absolute;
  right: 47px;
  top: 1385px;

  @media (max-width: 640px) {
    position: absolute;
    right: 160px;
    top: 219px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 1030px;
    width: 280px;
  }
}

.leftArrowCurved {
  position: relative;
  top: 165px;
  left: 138px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0 !important;
    left: 90px !important;
  }
}

.numbersSentes {
  padding: 23px;
  font-size: 32px;
  width: 33.3%;
  font-family: 'Rubik';
  font-weight: 500;

  @media (max-width: 640px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center !important;
    color: #33354e;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #33354e;
  }
}

.gridCard {
  display: grid;
  grid-template-columns: 100px 235px;
  grid-template-rows: 88px;
  margin-top: 20px;

  @media (max-width: 640px) {
    grid-template-columns: 78px 212px;
  }
}

.imgCard {
  justify-self: center;
  width: 60%;
  height: 60%;
}

.card {
  width: 412.1px;
  height: 244.18px;
  left: 266px;
  top: 12335.11px;
  background: #ffffff;
  border: 1.09894px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 11.874px;
  margin: 1%;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: auto;
    min-height: 224px;
    width: 350px;
    border-radius: 16.4841px;
    margin-bottom: 80px;
    box-shadow: 98.9046px 54.947px 43.9576px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 640px) {
    width: 93% !important;
    height: 350px;
    margin-right: unset;
    margin-top: 25px;
  }
}

.flexCard {
  place-content: center;
  display: flex;

  // margin-top: 20%;
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
}

.nameCard {
  height: 32px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  // color: #FFFFFF;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    color: #1f2148;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.positionCard {
  width: 212px;
  height: 24px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    color: #1f2148;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}

.feedback {
  width: 345px;
  height: 96px;
  left: 301px;
  top: 12432px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c2f62;
  margin-top: -13px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 280px;
    color: #33354e;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  @media (max-width: 640px) {
    width: 70%;
    font-size: 14px;
  }
}

.section4Container {
  position: relative;
  top: 25%;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0;
  }
}

.section8 {
  position: relative;
  top: 13%;
  display: flex;
  flex-direction: row;
  background: #ebf3ef;
  place-content: center;
  height: 697.15px;

  @media (max-width: 640px) {
    height: auto;
    padding-bottom: 140px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-bottom: 150px;
    height: auto !important;
  }
}

.borderSection11 {
  border: 1px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 20px;
  height: 700px;
  border-radius: 11px;
}

.slide-container {
  position: relative;
  top: 90px;
}

.section12Circle {
  position: absolute;
  left: 30%;
  margin-top: -249px;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: -165px;
  }
}

.section12CircleRtl {
  position: absolute;
  right: 30%;
  margin-top: -249px;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: -165px;
  }
}

.line {
  position: relative;
  /* height: 307.27px; */
  /* left: 1057.03px; */
  /* top: 7%; */
  height: 100%;
  width: 100%;
  left: 228px;
  transform: rotate(-8.32deg);

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    left: 350px;
  }
}

.lineRtl {
  position: relative;
  /* height: 307.27px; */
  /* left: 1057.03px; */
  /* top: 7%; */
  height: 100%;
  width: 100%;
  right: 228px;
  transform: rotate(-8.32deg);

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    right: 360px;
    transform: scaleX(-1);
  }
}

.ellipse {
  margin-top: -180px;
  margin-right: 640px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: -248px;
    padding-bottom: 70px;
  }
}

.borderSction14 {
  border: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: -webkit-center;
}

.section7Circle {
  position: absolute;
  // right: 435px;
  margin-top: -270px;

  @media (max-width: 640px) {
    display: none;
  }
}

.section7CircleRtl {
  position: absolute;
  left: 435px;
  margin-top: -270px;
  transform: scaleX(-1);

  @media (max-width: 640px) {
    display: none;
  }
}

.CloseCircle {
  display: grid;
  grid-template-columns: 240px 225px;
  grid-template-rows: 50px 50px;
  margin-top: 25px;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    display: block;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-bottom: 60px;
  }
}

.backSection8 {
  position: absolute;
  margin-top: 100px;
  display: inline;

  @media (max-width: 640px) {
    display: none;
  }
}

.backSection8Media {
  position: absolute;
  margin-top: 100px;
  display: none;

  @media (max-width: 640px) {
    display: inline;
    margin-top: 152px;
  }
}

.section8Elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  @media (max-width: 640px) {
    margin-top: 45px;
  }
}

.numbersSentesMedia {
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
}

.numbersSection8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 80%;
  }
}

.numbers {
  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 73%;
    margin-top: 90px;
  }
}

.AiOutlineArrowDown {
  border-radius: 50%;
  z-index: 70000;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  inset: auto 37px 107px auto;
  position: fixed;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #a9b9ca;
  box-sizing: border-box;
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px #888888;
  @media only screen and(max-width:1245px) and(min-width:640px) {
    display: none;
  }

  @media (max-width: 640px) {
    display: none;
  }
}

.bottombarTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;

  @media (max-width: 640px) {
    width: 331px;
    height: auto;
    font-weight: 500;
    font-size: 16px;
    margin-top: 28px;
    text-align: start;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.sectionRtl {
  direction: rtl !important;
}

.rtlFirstBackground {
  transform: scaleX(-1);
}

.smallButtonRtl {
  right: 0px;
  left: 71.51%;
  @media (max-width: 640px) {
    right: unset;
    left: unset;
  }
}

.dots {
  position: absolute;
  transform: scaleX(1);

  @media (max-width: 640px) {
    width: 100%;
    margin-inline-start: -13%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 500px;
  }
}

.dotsRtl {
  transform: scaleX(-1);

  @media (max-width: 640px) {
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    margin-inline-start: -13%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 500px;
  }
}

.animSection3 {
  transform: scaleX(1);
}

.animSection3Rtl {
  transform: scaleX(-1);
}

.swiper {
  width: 80%;
  height: fit-content;
}

.eachSlide {
  display: flex;
  flex-direction: row;
}

.topbarRtl {
  direction: rtl !important;
}

.langMenu {
  width: 160px;
  background: #33354e;
  fill-opacity: 0.1;
  border-radius: 8px;
  margin: 1px 5vw;
  height: 38px;
  display: flex;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 25px;
    border-radius: 2.59105px;
  }
}

.langMenuRtl {
  margin: 1px 0vw;
}

.langMenuIcon {
  margin-inline-start: 30px;
}

.langMenuTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  justify-content: space-between;
  text-transform: capitalize;
  width: 100%;
  text-align: start;
}

.section6phone {
  margin-left: 111px;
  height: 613px;
  width: 473px;
  position: relative;
  top: 140px;

  @media (max-width: 640px) {
    margin: auto;
    width: 104%;
    height: 420px;
    top: 0px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0;
    width: 106%;
    margin-left: 6%;
  }
}

.iphoneSection13 {
  display: block;

  @media (max-width: 640px) {
    display: none;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 309px;
  }
}

.iphoneSection13Media {
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
}

#buttonCreateApprove {
  background: #42c6a5;
}

#buttonCreateDisable {
  background: #ebf3ef;
}

.bottomArrow {
  position: relative;
  top: 10px;

  @media (max-width: 640px) {
    border-radius: 50%;
    cursor: pointer;
    inset: 23px auto 20px auto;
    width: 20px;
    height: 20px;
    border: 1px solid #a9b9ca;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

.menuTopBar {
  width: 100vw;
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  top: 66px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 44px;
    padding: 1px 40px;
  }
}

.closeTopBarMenu {
  top: 0px;
}

.closeTopBarMenuSection {
  margin-top: 75px;
}

.openTopBarMenuSection {
  margin-top: 66px;
}

.landingPagebody {
  overflow-x: hidden;
}

.menuTopBar .widgetIcon {
  width: 48px;
  height: 48px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 31px;
    height: 31px;
  }
}

.menuMobile .widgetIcon {
  width: 48px;
  height: 48px;

  @media (max-width: 640px) {
    margin-inline-start: 20px;
    margin-top: 8px;
    margin-bottom: 6px;
    width: 44px;
    height: 44px;
  }
}

.topnav {
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Rubik';
  font-style: normal;
  line-height: 24px;
  padding-inline-start: 60px;
  display: flex;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-inline-start: 36px;
  }
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  color: #a9b9ca;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  margin-inline-end: 80px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    line-height: 24px;
    padding: 9px 16px;
    margin-inline-end: 44px;
  }
}

.topnav a:hover {
  border-bottom: 3px solid #33354e;
}

.topnav a.active {
  border-bottom: 3px solid #33354e;
  font-weight: 500;
  color: #33354e;
}

.langBtn {
  display: flex;
  justify-content: space-between;
}

.flagImg {
  padding-inline-end: 24px;
  width: 17px;
}

.flagName {
  color: #ffffff;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.flagRow {
  padding: 14px 24px;
  background-color: #33354e;
  width: 100%;
  cursor: pointer;
}

.menuSide {
  display: flex;
  position: relative;
  align-items: center;
}

.menuSideRtl {
  margin-inline-start: 5vw;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
}

.topbarSide {
  position: relative;
}

.swiperSlide {
  border: 1.09894px solid #c5c7d1;
  box-sizing: border-box;
  margin: 1%;
  border-radius: 11.874px;
  // width: 500;
  width: 100%;
  height: 333;

  @media (max-width: 640px) {
    // margin-top: -67px;
  }
}

.stepContainer {
  @media (max-width: 640px) {
    padding-top: 74px;
  }
}

.section6 {
  margin-top: 90px;
  margin-inline-start: 5%;

  @media (max-width: 640px) {
    margin-top: 60px;
  }
}

.gif {
  width: 40px;

  @media (max-width: 640px) {
    width: 20px;
    margin: 25%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 25px;
    margin: 20%;
  }
}

.headerDark2 {
  font-family: 'Rubik';
  width: 100%;
  height: 114px;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: unset;

  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
    height: auto;
    padding-top: 10px;
  }
}

.headerDark6 {
  font-family: 'Rubik';
  width: 100%;
  top: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: unset;
  margin-bottom: 30px;
  margin-top: 55px;

  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
    height: auto;
    margin-top: 9px;
    margin-bottom: 2px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 24px;
    line-height: 36px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.headerDark3 {
  font-family: 'Rubik';
  width: 100%;
  height: unset;
  top: 220px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  color: #33354e;
  align-items: center;
  text-align: start;
  place-content: unset;
  margin-bottom: 20px;

  @media (max-width: 640px) {
    width: 85%;
    font-size: 20px;
    line-height: 32px;
    height: auto;
    font-weight: 400;
    margin-bottom: 10px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 34px;
  }
}

.sentence {
  font-family: 'Rubik';
  font-style: normal;
  color: #33354e;
  padding-inline-start: 12px;

  @media (max-width: 640px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #33354e;
    padding-inline-start: 12px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #33354e;
    margin-inline-start: 12px;
  }
}

.grid2 {
  @media (max-width: 640px) {
    padding-top: 12px;
  }
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'><path%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23444444'%2F><%2Fsvg>") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23444444'%2F%3E%3C%2Fsvg%3E") !important;
}

.section12 {
  background-color: #ffffff;
  padding-bottom: 200px;
  margin-top: -20vh;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-bottom: 166px;
  }
}

.section12Body {
  background-color: #ebf3ef;
  padding: 100px 268px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding: 100px 200px;
  }
}

.header12 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 85px;
  color: #33354e;
  width: 75%;

  @media (max-width: 640px) {
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    width: 84%;
    padding: 0px 8%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 32px;
    line-height: 48px;
  }
}

.rowItem {
  display: flex;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding-inline-start: 19px;
    padding-inline-end: 19px;
  }
}

.colItem {
  position: relative;
  width: 19%;
  height: 222px;
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    width: 48%;
    height: 158px;
    height: 170px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 143.71px;
    border-radius: 9.71013px;
    margin-bottom: 12.95px;
  }
}

.colTitle {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #33354e;
  text-align: center;
  width: 90%;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 640px) {
    font-size: 16px;
    margin-bottom: 1px;
    margin-top: 22px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.colText {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #7f7f7f;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 10px;
    line-height: 14px;
  }
}

.imgSection12_1 {
  z-index: 2;
  margin-bottom: -150px;
  transform: scaleX(-1);

  @media (max-width: 640px) {
    margin-bottom: 0px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 385px;
  }
}

.imgSection12_2 {
  z-index: 2;
  margin-bottom: -290px;
  margin-inline-start: -268px;

  @media (max-width: 640px) {
    background: #ebf3ef;
    margin-bottom: -48px;
    margin-inline-start: 0px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 400px;
    margin-bottom: -229px;
  }
}

.dotsSectio12 {
  z-index: 1;
  margin-bottom: -220px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 87%;
    margin-bottom: -166px;
  }
}

.dotsSectio12Rtl {
  z-index: 1;
  margin-bottom: -220px;
  transform: scaleX(-1);

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 87%;
    margin-bottom: -166px;
  }
}

.section14subHeader {
  padding: 50px;
  height: 129px;
}

.flexSection10 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 200px;

  @media (max-width: 640px) {
    background: url('https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section10Mobile.png');
    position: unset;
    margin-top: -10px;
    min-height: 80vh;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 120px;
  }
}

.row {
  display: flex;
}

.section4Container .headerDark {
  @media (max-width: 640px) {
    text-align: center;
    width: 75%;
  }
}

.section4Container .headerDarkRtl {
  @media (max-width: 640px) {
    text-align: center;
    width: 75%;
  }
}

.bottombar .flex {
  justify-content: space-between;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bottomArrowRtl {
  @media (max-width: 640px) {
    inset: 23px -15px 20px auto;
  }
}

.smallButtonM1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 230px;
  }
}

.section5 {
  @media (max-width: 640px) {
    padding-top: 68px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-top: 110px;
  }
}

.section5body {
  @media (max-width: 640px) {
    padding-top: 74px;
  }
}

.headerDark5 {
  @media (max-width: 640px) {
    width: 73%;
    text-align: center;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-bottom: 59px;
  }
}

.section6Mobile {
  @media (max-width: 640px) {
    margin-top: 60px !important;
    margin-right: 6% !important;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 60px !important;
  }
}

.space6 {
  @media (max-width: 640px) {
    padding-top: 30px;
  }
}

.btnMobile6 {
  @media (max-width: 640px) {
    margin-top: 60px !important;
  }
}

.size6 {
  width: 79%;
  place-content: unset !important;

  @media (max-width: 640px) {
    place-content: center !important;
    width: 86%;
  }
}

.sentesMedia1 {
  margin-top: -10px;
  margin-right: 135px;

  @media (max-width: 640px) {
    margin-top: 70px;
  }
}

.sentesMedia2 {
  margin-top: 100px;
  margin-right: -140px;
}

.sentesMedia3 {
  margin-top: 115px;
  margin-right: 135px;
}

.ourVideo {
  position: relative;
  top: 50px;
  height: unset;

  @media (max-width: 640px) {
    top: 0px;
    width: 340px !important;
    height: 197px !important;
    margin-inline-start: 10px;
  }
}

.videoSection10 {
  width: 100%;
  @media (max-width: 640px) {
    top: 0px;
  }
}

.headerVideo {
  @media (max-width: 640px) {
    display: unset;
    padding-top: 60px;
    padding-bottom: 17px;
    text-align: start;
    padding-inline-start: 25px;
  }
}

.text10 {
  display: none;

  @media (max-width: 640px) {
    display: unset;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #33354e;
    padding-bottom: 105px;
    text-align: start;
    padding-inline-start: 25px;
  }
}

.sectionRtlMobile {
  @media (max-width: 640px) {
    direction: rtl !important;
  }
}

.mobile11 {
  padding-top: 160px;

  @media (max-width: 640px) {
    padding-top: 80px;
    margin-top: -120px;
    background: transparent;
  }
}

.section9 {
  height: 110vh;
}

.section9 .subheaderDark {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.section10 {
  height: 120vh;
}

.cardCenter {
  width: 412.1px;
  height: 244.18px;
  left: 266px;
  top: 12335.11px;
  background: #ffffff;
  border: 1.09894px solid #c5c7d1;
  box-sizing: border-box;
  border-radius: 11.874px;
  margin: 1%;

  @media only screen and(max-width:1245px) and(min-width:640px) {
    width: 300px;
    height: 215px;
    background: #33354e;
    border-radius: 6px;
    margin-right: 21px;
    margin-bottom: 80px;
  }

  @media (max-width: 640px) {
    width: 93% !important;
    height: 400px;
    margin-right: unset;
  }
}

.headerDark14 {
  @media (max-width: 640px) {
    width: 88%;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 72%;
    place-content: unset !important;
    height: auto !important;
  }
}

.section3Merge {
  margin-inline-start: 10% !important;

  @media (max-width: 640px) {
    margin-inline-start: 6% !important;
  }
}

.section2 {
  padding-top: 80px;

  @media (max-width: 640px) {
    padding-top: 0px;
  }
}

.section13 {
  padding-top: 100px;
  background-color: #ffffff;

  @media (max-width: 640px) {
    padding-top: 0px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-top: 0px;
  }
}

.menuSideMobile {
  @media (max-width: 640px) {
    position: relative;
  }
}

.menuMobile {
  @media (max-width: 640px) {
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 84px;
    position: fixed;
    z-index: 3;
  }
}

.MenuIcon {
  @media (max-width: 640px) {
    margin-inline-end: 21px;
  }
}

.MenuIconRtl {
  @media (max-width: 640px) {
    transform: scaleX(-1);
  }
}

.closeMenuMobile {
  @media (max-width: 640px) {
    top: 0px !important;
  }
}

.section12Mobile {
  @media (max-width: 640px) {
    background: #ffffff;
    // padding-top: 50px;
  }
}

.section12MobileBody {
  @media (max-width: 640px) {
    background: #ebf3ef;
    padding-top: 67px;
    margin-top: -90px;
    padding-bottom: 90px;
  }
}

.section12Btn {
  @media (max-width: 640px) {
    background: #ffffff;
    border: 1px solid #33354e;
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 220px;
    color: #33354e;
    padding: 11px 0;
    display: flex;
    justify-content: center;
    margin: 0px 19.5%;
    margin-top: 64px;
    cursor: pointer;
  }
}

.borderSction16 {
  border-top: 0.912981px solid #c5c7d1;
  box-sizing: border-box;
  text-align: -webkit-center;
}

.section16 {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding-top: 19px;
  padding-bottom: 75px;

  @media (max-width: 640px) {
    padding-bottom: 19px;
    flex-direction: column;
  }
}

.part {
  position: relative;
  width: 33%;
  width: 27%;
  @media (max-width: 640px) {
    width: 18%;
  }
}
.partimages {
  position: relative;
  width: 33%;

  @media (max-width: 640px) {
    width: 65%;
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
  }
}

.widgetIcon16 {
  width: 80px;

  @media (max-width: 640px) {
    width: 40px;
  }
}

.middlePart {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #33354e;

  @media (max-width: 640px) {
    width: 80%;
    font-size: 14px;
  }
}

.middlePart .partCol {
  border-bottom: 1px solid #33354e;
  cursor: pointer;
  width: fit-content;
}

.middlePart .partColSpace {
  margin-inline-end: 20px;

  @media (max-width: 640px) {
    margin-inline-end: 0px;
  }
}

.middlePart .partRow {
  margin-top: 8px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contactIcon {
  margin-inline-end: 15px;
  cursor: pointer;
}

.section5_1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: auto !important;
  }
}

.section6_1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-top: 100px;
    height: auto !important;
  }
}

.section7 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: auto !important;
    padding-bottom: 90px;
  }
}

.flexSection6 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 240px !important;
  }
}

.section8_1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: auto !important;
  }
}

.integratedLable {
  font-family: 'Rubik';
  font-style: normal;
  color: #33354e;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    height: 40% !important;
  }
}

.integratedImg1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    max-height: 60px;
    max-width: 130px;
  }
}

.integratedImg2 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    max-height: 60px;
    max-width: 130px;
    margin-top: 19px !important;
  }
}

.integratedImgDiv {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 60% !important;
  }
}

.wooImg {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 19px !important;
  }
}

.spaceI {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-inline-start: 53px;
  }
}

.textSect9 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 22px !important;
    line-height: 36px !important;
    font-weight: 400 !important;
    top: 0 !important;
  }
}

.section9body {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    left: 138px !important;
  }
}

.title1Sect9 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    place-content: unset !important;
    top: 0 !important;
    margin-top: 100px;
    direction: ltr !important;
  }
}

.title2Sect9 {
  font-size: 20px !important;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0 !important;
    margin-top: 20px;
  }
}

.title3Sect9 {
  font-size: 20px !important;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    top: 0 !important;
    height: auto !important;
    margin-bottom: 27px;
  }
}

.section9MarginTop {
  margin-top: 50px;
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-top: 20px;
  }
}
.section9_1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 113vh !important;
  }
}

.colImg {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    width: 35px;
    text-align: center;
  }
}

.section13body {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    margin-bottom: 10px !important;
  }
}

.section13_1 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: auto !important;
  }
}

.section14 {
  padding-top: 100px;
  padding-bottom: 90px;

  @media (max-width: 640px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    padding-top: 25px;
    padding-bottom: 145px;
  }
}

.headerSec3 {
  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    place-content: unset !important;
  }
}
.codeNumMedia {
  @media (max-width: 640px) {
    display: flex;
    width: 309px;
    align-self: center;
  }
}
