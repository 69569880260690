@import url('https://fonts.googleapis.com/css?family=Rubik:500,400,700');

.smallPlaceholder {
  font-family: Rubik;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #999999;
  margin-top: 10px;
  padding-inline-start: 16px;

  @media (max-width: 640px) {
    margin-top: 5px;
    font-size: 9px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    display: none;
  }
}

.inputText {
  padding-top: 0.7%;
  height: 55px;
  width: 100%;
  min-width: 300px;
  max-width: 335px;
  border-radius: 8px;
  border: 0px solid;
  margin-bottom: 2.5vh;
  font-family: Rubik;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: start;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 640px) {
    width: 100%;
    align-items: center;
    padding-top: 1vh;
    font-size: 1vw;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 8px;
    height: 60px;
  }
}

.feildError {
  border: 1px solid red !important;
}

.inputfiled {
  // height: 24px;
  width: 90%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: start;
  font-family: 'Rubik';
  padding-inline-start: 16px;
  background-color: transparent;
  border-color: transparent;
  color: white;

  @media (max-width: 640px) {
    font-size: 12px;
    height: 25px;
    font-weight: 400;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    height: 4.4vh;
  }
}

.inputfiledBig {
  height: 49px;
  padding-inline-start: 16px;

  @media (max-width: 640px) {
    height: 37px;
  }
}

.inputValue {
  height: 45px;
  padding-inline-start: 16px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    height: 4.4vh;
  }
}

#iconPosition {
  margin-top: -24px;

  @media (max-width: 640px) {
    margin-top: -20px;
  }
}

.iconStyle {
  color: #c5c7d1;
  font-size: 24px;
  padding: 11px 22px 0px 22px;

  @media (max-width: 640px) {
    font-size: 14px;
    padding: 18px 22px 0px 22px;
  }
}

.inputItemRight {
  flex-grow: 0;
  text-align: end;
}

.submitError {
  color: red;
  font-size: 14px;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}

.inputItem {
  flex-grow: 5;
}

.flex {
  display: flex;
}

.placeholderSelect {
  font-size: 14px;
  padding-top: 17px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'Rubik';
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
  margin-inline-start: 11px;
  width: 457px;
  //    width: 560px;

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 90%;
    height: 70%;
  }

  @media (max-width: 640px) {
    font-weight: normal;
    font-size: 12px;
    padding-top: 12px;
  }
}

.placeholderSelect:focus-visible {
  outline: none;
}
.inputfiled:focus-visible {
  outline: none;
}

::-moz-selection {
  background-color: #000000;
}
.ReasonForRequest {
  color: #ffffff;
  margin-inline-start: 10px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
