.subTitle {
  text-align: start;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  align-items: center;
  padding: 8vw 0vw 1vw 0vw;

  @media (max-width: 1800px) {
    font-size: 20px;
  }

  @media only screen and(max-width: 1300px) and (min-width: 641px) {
    font-size: 16px;
    line-height: 24px;
  }
}
