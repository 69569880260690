.topbarMenu {
  z-index: 3 !important;
  height: 100vh;
  width: 100vw;
  background-color: #45745e;
}

.topbar {
  @media (max-width: 640px) {
    width: 100vw;
    background: #45745e;
    backdrop-filter: blur(51.821px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 3;
    border-bottom: 1px solid #e8ecef;
    padding: 8px 0px;
  }
}

.widgetIcon {
  @media (max-width: 640px) {
    margin-inline-start: 21px;
    width: 44px;
  }
}

.closeIcon {
  @media (max-width: 640px) {
    margin-inline-end: 21px;
    width: 16px;
  }
}

.bottombar {
  @media (max-width: 640px) {
    background: #ffffff;
    padding-top: 21px;
    padding-bottom: 21px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 3;
  }
}

.registerBtn {
  @media (max-width: 640px) {
    background: #33354e;
    border: none;
    border-radius: 6px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    width: 120px;
    height: 48px;
    margin-inline-start: 20px;
  }
}

.menuBody {
  @media (max-width: 640px) {
    padding-top: 120px;
  }
}

.row {
  @media (max-width: 640px) {
    display: flex;
    justify-content: space-between;
    margin-inline-start: 18px;
    margin-inline-end: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #a9b9ca;
  }
}

.rowTitle {
  @media (max-width: 640px) {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }
}

.rowIcon {
  @media (max-width: 640px) {
    width: 6.41px;
    height: 10.83px;
    margin-inline-end: 8px;
  }
}

.select {
  @media (max-width: 640px) {
    margin: 0px 20px;
    margin-top: 50px;
  }
}
