.card {
  .cardHeader {
    // display: flex;
    justify-content: space-between;
    align-items: center;

    .cardTitle {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 60px;
      color: #ffffff;
      display: flex;
      width: 100%;
      align-items: center;

      @media (max-width: 1800px) {
        font-size: 43px;
      }

      @media only screen and(max-width: 1300px) and (min-width: 641px) {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 4vh;
  color: #000000;
  padding: 1vw 0vw 0vw 0vw;
  margin-bottom: -5px;
}
